const List = ({ list, removeToDo }) => {
    return (
        <div>
            { list && list.map((item, index) => 
            <div key={ index }>
                <p key={index}> { item }</p>
                <button onClick={ () => removeToDo(item) }>Remove</button>
            </div>
            ) 
        }
        </div>
    )
}

export default List