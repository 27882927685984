import './App.css';
import { useState } from 'react';
import Form from './components/form';
import List from './components/list';

function App() {
  const [toDo, setToDo] = useState([]);

  const addToDo = newToDo => setToDo([...toDo, newToDo])
  const removeToDo = toRemove => setToDo(toDo.filter(ele => ele !== toRemove))



  return (
    <div className="App">
      <Form newToDo={ addToDo } />
      <List list={ toDo } removeToDo={ removeToDo } />
    </div>
  );
}

export default App;