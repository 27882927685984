import { useState } from "react"

const Form = ({ newToDo }) => {
    const [todo, setTodo] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        newToDo(todo)
    }

    return (
        <form onSubmit={ handleSubmit }>
            <input
                value={ todo }
                onChange={ e => setTodo(e.target.value) }
            />

            <button type="submit">Adicionar</button>
        </form>
    )
}


export default Form